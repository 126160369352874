<template>
  <el-main>
    <el-form class="el-form-search" label-width="90px">
      <el-form-item label="会员名称：">
        <el-input v-model="name" size="small" placeholder="请输入服务名称"></el-input>
      </el-form-item>
      <el-form-item label=" " label-width="25px">
        <el-button type="primary" size="small" @click="getList">搜索</el-button>
        <el-button type="text" size="small" @click="cancelSearch">清空搜索条件</el-button>
      </el-form-item>
    </el-form>
    <el-table :data="list" :header-cell-style="{ 'background-color': '#F8F9FA' }">
      <template slot="empty">
        <No />
      </template>
      <el-table-column prop="name" label="会员名称" align="center"></el-table-column>
      <el-table-column prop="conditions" label="成为前置身份条件（只满足一个即可）" align="center">
        <template v-slot="{ row }">
          <div v-if="row.type > 1">
            <span v-for="(item, index) in row.conditions" :key="index">
              {{
                item == 0
                  ? '个人会员'
                  : item == 1
                  ? '企业会员'
                  : item == 2
                  ? '个人会员商家'
                  : item == 3
                  ? '企业会员商家'
                  : item == 4
                  ? '供应商品商家'
                  : item == 5
                  ? '代销商家'
                  : item == 6
                  ? '产品合伙人'
                  : item == 7
                  ? '城市合伙人'
                  : '市场合伙人'
              }}
              {{ index + 1 != row.conditions.length ? '、' : '' }}
            </span>
          </div>
          <div v-else>--</div>
        </template>
      </el-table-column>
      <el-table-column prop="qualification" label="上传商品服务资格" align="center">
        <template v-slot="{ row }">
          <span v-for="(item, index) in row.qualification" :key="index">
            {{ item == 1 ? '原料时价' : item == 2 ? 'CRM管理' : item == 3 ? '应用数据' : item == 6 ? 'AI广告' : item == 7 ? '项目合作' : item == 8 ? 'PE制品' : item == 9 ? '生物物料' : '特种塑料' }}
            {{ index + 1 != row.qualification.length ? '、' : '' }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" fixed="right" align="center" width="200px">
        <template v-slot="{ row }">
          <el-button type="text" @click="edit(row)">编辑</el-button>
          <el-button type="text" @click="deleteService([row.id])">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog title="编辑" :visible.sync="showEdit" width="680px">
      <el-form ref="form" :model="form" :rules="rules" label-width="180px">
        <el-form-item label="会员名称：">
          <el-input v-model="form.name" disabled></el-input>
        </el-form-item>
        <template v-if="form.type > 1">
          <el-form-item label="前置条件身份：" prop="condition">
            <el-select v-model="form.condition" multiple clearable placeholder="支持多选">
              <el-option v-for="(item, index) in condition" :key="index" :label="item.name" :value="item.type"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="上传商品服务资格：" prop="qualifications">
            <el-select v-model="form.qualifications" multiple clearable placeholder="支持多选">
              <el-option v-for="(item, index) in qualifications" :key="index" :label="item.name" :value="item.type"></el-option>
            </el-select>
          </el-form-item>
        </template>
        <el-form-item label="购买商品/服务金额大于：" prop="price" v-if="form.type == 0 || form.type == 1">
          <el-input v-model="form.price">
          </el-input>
        </el-form-item>
        <el-form-item label="分享小程序链接次数：" prop="times" v-if="form.type == 0 || form.type == 1">
          <el-input v-model="form.times">
            <template slot="append">次</template>
          </el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showEdit = !1">取 消</el-button>
        <el-button type="primary" @click="confirmEdit">确 定</el-button>
      </span>
    </el-dialog>
  </el-main>
</template>

<script>
export default {
  data() {
    return {
      name: '',
      list: [],
      showEdit: !1,
      condition: [
        {
          name: '个人会员',
          type: 0,
        },
        {
          name: '企业会员',
          type: 1,
        },
        {
          name: '个人会员商家',
          type: 2,
        },
        {
          name: '企业会员商家',
          type: 3,
        },
        {
          name: '供应商品商家',
          type: 4,
        },
        {
          name: '代销商家',
          type: 5,
        },
        {
          name: '产品合伙人',
          type: 6,
        },
        {
          name: '城市合伙人',
          type: 7,
        },
        {
          name: '市场合伙人',
          type: 8,
        },
      ],
      qualifications: [
        {
          name: '原料时价',
          type: 1,
        },
        {
          name: 'CRM管理',
          type: 2,
        },
        {
          name: '应用数据',
          type: 3,
        },
        {
          name: 'AI广告',
          type: 6,
        },
        {
          name: '项目合作',
          type: 7,
        },
        {
          name: 'PE制品',
          type: 8,
        },
        {
          name: '生物物料',
          type: 9,
        },
        {
          name: '特种塑料',
          type: 10,
        },
      ],
      rules: {
        condition: { required: true, type: 'array', message: '请选择前置条件身份', trigger: 'change' },
        qualifications: { required: true, type: 'array', message: '请选择上传商品服务资格', trigger: 'blur' },
        price: { required: true, message: '请填写购买商品/服务金额', trigger: 'blur' },
        times: { required: true, message: '请填写分享小程序链接次数', trigger: 'change' },
      },
      form: {
        name: '',
        condition: [],
        qualifications: [],
        price: '',
        times: '',
      },
    };
  },
  created() {
    this.getList();
  },
  methods: {
    cancelSearch() {
      this.name = '';
      this.getList();
    },
    edit(row) {
      this.form.type = row.type;
      if (row.type > 1) {
        this.form.condition = row.conditions.map(Number);
        this.form.qualifications = row.qualification.map(Number);
      } else {
        this.form.price = row.conditions.price;
        this.form.times = row.conditions.times;
      }
      this.form.name = row.name;
      this.form.id = row.id;
      this.showEdit = !0;
    },
    confirmEdit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let data = {
            id: this.form.id,
          };
          if (this.form.type > 1) {
            data.condition = this.form.condition;
            data.qualifications = this.form.qualifications;
          } else {
            data.condition = {
              price: this.form.price,
              times: this.form.times,
            };
          }
          this.$axios.post(this.$api.gateway.member.memberSetEdit, data).then(res => {
            if (res.code == 0) {
              this.showEdit = !1;
              this.getList();
              this.$message.success('编辑成功');
            } else {
              this.$message.error(res.msg);
            }
          });
        }
      });
    },
    getList() {
      this.$axios
        .post(this.$api.gateway.member.setList, {
          name: this.name,
        })
        .then(res => {
          if (res.code == 0) {
            let list = res.result.list;
            this.list = list;
          } else {
            this.$message.error(res.msg);
          }
        });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background: #fff;
  .el-select {
    width: 100%;
  }
}
</style>